<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > > {{  $t('header.strategy.title') }}</div>
        </div>
    </div>
    <div class="container mt-5 text-center mb-5" dir="rtl">
        <div>
            <img src="../../assets/journee_africaine_anti_corruption.jpg" class="img-thumbnail img-fluid" alt="">
        </div>
        <div class="title">
            {{  $t('header.strategy.titlePage') }}
        </div>
        <div class="row mb-5 mt-5 text-center">
            <div class="col-md-4">
                <img style="border: 1px solid #254C31" src="../../assets/strategy/1.jpg" alt="">
                <div class="d-flex align-items-center justify-content-center">
                    <router-link class="line download" to="/speech-president" @click="scrolltop">
                        <span>{{  $t('header.welcome.strategy.titleDocAr') }}</span>
                    </router-link>
                </div>
            </div>
            <div class="col-md-4">
                <img style="border: 1px solid #254C31" src="../../assets/strategy/2.jpg" alt="">
                <div class="d-flex align-items-center justify-content-center">
                    <router-link class="line download" to="/speech-president" @click="scrolltop">
                        <span>{{  $t('header.welcome.strategy.titleDocEn') }}</span>
                    </router-link>
                </div>
            </div>
            <div class="col-md-4">
                <img style="border: 1px solid #254C31" src="../../assets/strategy/3.jpg" alt="">
                <div class="d-flex align-items-center justify-content-center">
                    <router-link class="line download" to="/speech-president" @click="scrolltop">
                        <span>{{  $t('header.welcome.strategy.titleDocFr') }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "strategy"
    }
</script>

<style scoped>
    .download {
        display: flex;
        align-items: center;
        margin-top: calc(18/16 * var(--default-size-unit));
        border: none;
        border-radius: calc(4/16 * var(--default-size-unit));
        color: var(--white);
        /*width: -webkit-fit-content;*/
        width: 205px!important;
        padding: calc(7/16 * var(--default-size-unit)) calc(10/16 * var(--default-size-unit));
        background: #254C31!important;
        text-decoration: none;
    }
    .download svg {
        -webkit-margin-end: calc(8/16 * var(--default-size-unit));
        margin-inline-end: calc(8/16 * var(--default-size-unit));
    }
    ul.no-bullets {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .mot_president{
        color: rgba(50, 54, 57, 0.83);
    }
    .nav-link{
        color: rgba(80, 84, 87, 0.8) !important;
    }
    .active{
        color: rgba(28, 28, 28, 0.96)!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
    }
    .back-gray p{
        line-height: 200%;
    }
</style>
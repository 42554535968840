<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('header.supremeAuthority.title') }} > {{ $t('header.supremeAuthority.membersCouncil.title') }}</div>
        </div>
    </div>
    <div class="container mt-5 text-center mb-5" dir="rtl">
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">{{ $t('header.supremeAuthority.membersCouncil.members.title') }}</button>
                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{{ $t('header.supremeAuthority.membersCouncil.missions.title') }}</button>
                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">{{ $t('header.supremeAuthority.membersCouncil.courses.title') }}</button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="title">
                    {{ $t('header.supremeAuthority.membersCouncil.titlePage') }}
                </div>
                <div class="mot_president">
                    <ul style="text-align: right; padding-right: 40px" class="no-bullets">
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_1') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_2') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_3') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_4') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_5') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_6') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_7') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_8') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_9') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_10') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_11') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-user" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_1.text_12') }}
                    </span>
                        </li>
                    </ul>
                </div>
                <div class="title">
                    {{ $t('header.supremeAuthority.membersCouncil.content_2.title') }}
                </div>
                <div class="mot_president">
                    <ul style="text-align: right;padding-right: 40px" class="no-bullets">
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_1') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_2') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_3') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_4') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_5') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_6') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_7') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_8') }}
                    </span>
                        </li>
                        <li>
                    <span><font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('header.supremeAuthority.membersCouncil.content_2.text_9') }}
                    </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

            </div>
            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "membersCouncil"
    }
</script>

<style scoped>
    ul.no-bullets {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .mot_president{
        color: rgba(50, 54, 57, 0.83);
    }
    .nav-link{
        color: rgba(80, 84, 87, 0.8) !important;
    }
    .active{
        color: rgba(28, 28, 28, 0.96)!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
    }
    .back-gray p{
        line-height: 200%;
    }
</style>
<template>
    <div class="container" dir="rtl">

        <div class="row mt-5">
            <div class="col-md-8 activities_president">
                <div class="d-flex" style="justify-content: space-between">
                    <div class="section-title">
                        {{  $t('header.welcome.nationalActivities') }}
                    </div>
                    <div>
                        <router-link class="url-deco" to="/national-articles" @click="scrolltop">
                            <span style="color: rgb(213, 166, 42);font-weight: bold"> >> </span>{{  $t('header.welcome.allNationalActivities') }}
                        </router-link>
                    </div>
                </div>
                <div class="p-2 mt-3" v-for="item in thirdNationalArticles" >
                    <router-link :to="{name: 'Detail_article', params: {id: item.uuid}}" @click="scrolltop">
                        <div class="date-article">
                            <div class="rec"></div>
                            <span>{{ date_article(item.date_article) }}</span>
                        </div>
                        <div class="desc-article">
                            <h6>{{item.title}}</h6>
                            <p>{{item.description}} ...</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-md-4 text-center">
                <img :src="'president.jpg'" class="img-fluid img-thumbnail mt-2" height="500" alt="">
                <div class="d-flex align-items-center justify-content-center">
                    <router-link class="line download" to="/president" @click="scrolltop">
                        <span>{{  $t('header.welcome.speechPresident') }}</span>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="row mt-5">
            <div class="col-md-8 activities_president">
                <div class="d-flex" style="justify-content: space-between">
                    <div class="section-title">
                        {{  $t('header.welcome.internationalActivities') }}
                    </div>
                    <div>
                        <router-link class="url-deco" to="/international-articles" @click="scrolltop">
                            <span style="color: rgb(213, 166, 42);font-weight: bold"> >> </span>{{  $t('header.welcome.allInternationalActivities') }}
                        </router-link>
                    </div>
                </div>
                <div class="p-2 mt-3" v-for="item in thirdInternationalArticles" >
                    <router-link :to="{name: 'Detail_article', params: {id: item.uuid}}" @click="scrolltop">
                        <div class="date-article">
                            <div class="rec"></div>
                            <span>{{ date_article(item.date_article) }}</span>
                        </div>
                        <div class="desc-article">
                            <h6>{{item.title}}</h6>
                            <p>{{item.description}} ...</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-md-4 text-center">
                <!--<img :src="'strategy.jpg'" height="400" alt="">-->
                <!--<div class="d-flex align-items-center justify-content-center">-->
                    <!--<router-link class="line download" to="/speech-president-council" @click="scrolltop">-->
                        <!--<span>{{  $t('welcome.speechPresidentCouncil') }}</span>-->
                    <!--</router-link>-->
                <!--</div>-->
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="mt-2 mb-4 text-center space_alert d-none">
            <ul class="mb-0" style="list-style-type: none">
                <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                </li>
            </ul>
        </div>

        <div class="row mail_contact">
            <div class="col-lg-5">
                <label>{{  $t('welcome.mailTitle') }}</label>
                <form @submit.prevent="saveMail" novalidate style="height: 70px!important;">
                    <fieldset>
                        <div class="input">
                            <input type="email" v-model="subscribers.email" placeholder="اكتب بريدك الإلكتروني هنا">
                            <button style="background-color: white; height: 50px; width: 70px; padding: 0!important;" class="btn btn-outline-success subscribe">
                                <!--<img :src="'images/icons/test.png'" height="16" alt="">-->
                                اشترك</button>
                        </div>
                    </fieldset>
                </form>
                <div class="recaptcha" dir="ltr">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy"  target="_blank" rel="noopener noreferrer">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms"  target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.
                </div>
            </div>
            <div class="col-lg-2">
                <div class="devider"></div>
            </div>
            <div class="col-lg-5">
                <div class="d-flex social_media">
                    <span>مواقع التواصل الإجتماعي: </span>
                    <a href="https://www.facebook.com/profile.php?id=100085245763587&_rdc=2&_rdr" target="_blank"  rel="noopener noreferrer">
                        <img :src="'images/icons/facebook.png'" height="24" alt="">
                    </a>
                    <a href="https://www.youtube.com/channel/UCEAx0FKBd7ig-tmcvW6fepQ" target="_blank"  rel="noopener noreferrer">
                        <img :src="'images/icons/youtube.png'" height="24" alt="">
                    </a>
                </div>
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="d-flex" style="justify-content: space-between">
            <div class="section-title">
                {{  $t('header.welcome.platforms.title') }}
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-sm-4 mt-4">
                <div class="card" style="min-height: 200px!important;">
                    <div class="card-body text-center platform">
                        <a href="#" style="text-decoration: none!important;color: #1F6614">
                            <img :src="'platform/2.png'" width="100" alt=""><br>
                            <span>منصة التصريح</span><br>
                            <span>الالكتروني بالممتلكات </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 mt-4">
                <div class="card" style="min-height: 200px!important;">
                    <div class="card-body text-center platform">
                        <a href="https://balighna.hatplc.dz/" target="_blank" style="text-decoration: none!important;color: #1F6614">
                            <img :src="'platform/1.png'" width="100" alt=""><br>
                            <span>المنصــــة الرقميــة</span><br>
                            <span> للتبليـــغ والإخطــار "بلغنــــــــا"</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 mt-4">
                <div class="card" style="min-height: 200px!important;">
                    <div class="card-body text-center platform">
                        <a href="https://naracom.hatplc.dz/" target="_blank" style="text-decoration: none!important;color: #1F6614">
                            <img :src="'platform/3.png'" width="100" alt=""><br>
                            <span>المنصة الرقمية</span><br>
                            <span> للشبكة الجزائرية للشفافية "نراكـــــم"</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="MuiBox-root css-ubepeg"></div>

        <div class="d-flex" style="justify-content: space-between">
            <div class="section-title">
                {{  $t('header.announcements.title') }}
            </div>
        </div>

        <div class="row mb-5 mt-5 text-center">
            <div class="col-12 col-sm-6 col-md-4 mt-4" v-for="item in latestAdvertisements">
                <div class="card">
                    <div class="card-body advertisement_css">
                        <h5 class="card-title">{{item.title}}</h5>
                        <div class="date-article">
                            <span>{{ date_article(item.date_article) }}</span>
                        </div>
                        <p class="card-text">{{item.description}}</p>
                        <router-link :to="{name: 'Detail_article', params: {id: item.uuid}}" class="btn btn-success">
                            معرفة المزيد
                        </router-link>
                        <!--<a href="#" class="btn btn-success">معرفة المزيد</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';

    import 'vue3-carousel/dist/carousel.css';
    export default {
        name: "homeComponent",
        data(){
            return {
                latestAdvertisements:Array,
                thirdNationalArticles:Array,
                thirdInternationalArticles:Array,
                url_back: process.env.VUE_APP_ROOT_API + 'uploads/',
                allArticles:this.allArticles,
                videos:this.videos,
                subscribers:{},
                email:'',
                errors:[]
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        created(){
            // this.onready;
        },
        mounted(){
            this.getVideos();
            this.getLatestAdvertisements();
            this.getThirdNationalArticles();
            this.getThirdInternationalArticles();
            // this.recaptcha();
        },
        computed: {

        },

        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            },

            async getThirdNationalArticles(){
                $('.wait_circle').removeClass('d-none');
                let url = process.env.VUE_APP_ROOT_API +'api/national/third/latest_articles';
                await axios.get(url).then(response =>{
                    this.thirdNationalArticles = response.data.national_third_latestArticles;
                }).catch(error => {
                    console.log(error);
                });
            },

            async getThirdInternationalArticles(){
                $('.wait_circle').removeClass('d-none');
                let url = process.env.VUE_APP_ROOT_API +'api/international/third/latest_articles';
                await axios.get(url).then(response =>{
                    this.thirdInternationalArticles = response.data.international_third_latestArticles;
                }).catch(error => {
                    console.log(error);
                });
            },

            async getVideos(){
                let url = process.env.VUE_APP_ROOT_API +'api/videos';
                await axios.get(url).then(response =>{
                    this.videos = response.data.videos;
                    $('.wait_circle').addClass('d-none');
                }).catch(error => {
                    console.log(error);
                });
            },

            async getLatestAdvertisements(){
                $('.wait_circle').removeClass('d-none');
                let url = process.env.VUE_APP_ROOT_API + 'api/latest_advertisements';
                await axios.get(url).then(response =>{
                    this.latestAdvertisements = response.data.latest_advertisements;
                }).catch(error => {
                    console.log(error);
                });
            },

            async saveMail(){
                $(".space_alert").removeClass('d-none');
                $(".space_alert").removeClass('alert-danger');
                $(".space_alert").removeClass('alert-success');
                this.errors = [];
                if(!this.subscribers.email){
                    $(".space_alert").addClass('alert alert-danger');
                    this.errors.push("أدخل الايميل");
                    setTimeout(function() {
                        $(".space_alert").addClass('d-none');
                    }, 7000);
                }else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.subscribers.email)) {
                    $(".space_alert").addClass('alert alert-danger');
                    this.errors.push("يرجى كتابة البريد الالكتروني بشكل صحيح");
                    setTimeout(function() {
                        $(".space_alert").addClass('d-none');
                    }, 7000);
                }
                if(!this.errors.length){

                    this.$recaptchaLoaded().then(() => {
                        // console.log("recaptcha loaded");
                        this.$recaptcha("saveMail").then((token) => {
                            let formData = new FormData();
                            // const tokens = this.recaptcha();
                            formData.append('email', this.subscribers.email);
                            formData.append('token', token);
                            let url_store_email = process.env.VUE_APP_ROOT_API + "api/save_mail";

                            axios.post(url_store_email, formData).then((response) => {
                                console.log(response);
                                if(response.data.code ===200){
                                    this.subscribers.email = '';
                                    $(".space_alert").addClass('alert alert-success');
                                    this.errors.push(response.data.message);
                                    setTimeout(function() {
                                        $(".space_alert").addClass('d-none');
                                    }, 7000);
                                }else if(response.data.code ===303) {
                                    $(".space_alert").addClass('alert alert-danger');
                                    this.errors.push(response.data.message);
                                    setTimeout(function() {
                                        $(".space_alert").addClass('d-none');
                                    }, 7000);
                                }
                            }).catch(error =>{
                                this.errors.push(error.response);
                            })
                        });
                    });
                }
            },

            date_article(value){
                let day = moment(value).format('DD');
                let month = moment(value).format('MM');
                let year = moment(value).format('YYYY');

                if(month === "01"){
                    month = "جانفي";
                }else if(month === "02"){
                    month = "فيفري";
                }else if(month === "03"){
                    month = "مارس";
                }else if(month === "04"){
                    month = "أفريل";
                }else if(month === "05"){
                    month = "ماي";
                }else if(month === "06"){
                    month = "جوان";
                }else if(month === "07"){
                    month = "جويلية";
                }else if(month === "08"){
                    month = "أوت";
                }else if(month === "09"){
                    month = "سبتمبر";
                }else if(month === "10"){
                    month = "أكتوبر";
                }else if(month === "11"){
                    month = "نوفمبر";
                }else if(month === "12"){
                    month = "ديسمبر";
                }
                return day +' '+  month +' '+ year;
            },
        },
    }

</script>

<style scoped>
    .platform a span{
        font-size: 17px!important;
        font-weight: bold;
    }
    .advertisement_css h5,p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .subscribe:hover{
        color: #17A575!important;
    }
    .recaptcha {
        font-size: 13px;
    }

    .recaptcha a {
        text-decoration: underline;
        color: white!important;
    }
    .url-deco{
        text-decoration: none!important;
        color: #1a1e21;
        font-size: var(--m-font-size);
        font-family: NizarCoconKurdishBold!important;
    }
    .url-deco:hover{
        color: #039654;
        font-weight: bolder;
        text-decoration: underline!important;
    }
    .activities_president a{
        text-decoration: none;
    }
    .title_video span{
        font-size: var(--s-font-size);
        font-weight: 700;
        line-height: calc(23/16 * var(--default-size-unit));
        /*max-width: calc(295/16 * var(--default-size-unit));*/
        /*white-space: nowrap;*/
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
    }

    .css-ubepeg {
        position: relative;
        margin-top: 3rem;
        margin-bottom: 7rem;
        height: 1px;
    }
    .css-ubepeg:before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        margin-right: auto;
        margin-top: 40px;
        margin-left: auto;
        max-width: 1200px;
        height: 4px;
        background-image: linear-gradient(to left, transparent, #254C31, transparent);
    }
    .section-title {
        font-size: var(--m-font-size);
        font-weight: bold;
        line-height: calc(40/16 * var(--default-size-unit));
        padding: calc(5/16 * var(--default-size-unit)) calc(10/16 * var(--default-size-unit));
        background: #254C31!important;
        border-radius: calc(4/16 * var(--default-size-unit));
        color: var(--white);
        /*max-width: 200px;*/
        font-family: NizarCoconKurdishBold!important;
    }
    .desc-article h6{
        color: var(--secondary-text-color);
        font-size: var(--m-font-size)!important;
    }
    .desc-article p{
        /*font-size: var(--m-font-size)!important;*/
        /*font-family: coconNextArabic!important;*/
        color: #67634c !important;
        font-size: var(--m-font-size);
        font-weight: 500;
        line-height: calc(23/16 * var(--default-size-unit));
        /*max-width: calc(295/16 * var(--default-size-unit));*/
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
    }
    .date-article{
        display: flex;
        align-items: center;
        /*font-family: DroidArabic;*/
        margin-bottom: 10px;
        color: #1b1c26;
    }
    .rec{
        height: 8px;
        width: 40px;
        background-color: rgb(213, 166, 42);
        /*margin-right: 10px;*/
        margin-left: 20px;
        margin-bottom: 0;
    }
    .desc-article h6{
        /*font-family: DroidArabic;*/
        font-weight: bold;
        font-size: 13px;
    }
    
    .desc-article a{
        text-decoration: none;
        color: black;
    }
    .download {
        display: flex;
        align-items: center;
        margin-top: calc(18/16 * var(--default-size-unit));
        border: none;
        border-radius: calc(4/16 * var(--default-size-unit));
        color: var(--white);
        /*width: -webkit-fit-content;*/
        width: 205px!important;
        padding: calc(7/16 * var(--default-size-unit)) calc(10/16 * var(--default-size-unit));
        background: #254C31!important;
        text-decoration: none;
    }
    .download svg {
        -webkit-margin-end: calc(8/16 * var(--default-size-unit));
        margin-inline-end: calc(8/16 * var(--default-size-unit));
    }
    .line {
        display: flex;
        width: 100%;
        background: var(--secondary-background-color);
    }

    .carousel__item {
        min-height: 200px;
        width: 100%;
        /*background-color: var(--vc-clr-primary);*/
        color:  var(--vc-clr-white);
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-size: cover;
        cursor: pointer;
        overflow: hidden;
    }
    .carousel__item a{
        text-decoration: none;
    }
    .carousel__slide {
        padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
        border: 5px solid white;
        background-color: #35805d!important;
    }
    .carousel__next svg{
        color: red!important;
    }

    .carousel__slide > .carousel__item {
        transform: scale(1);
        opacity: 0.5;
        transition: 0.5s;
    }
    .carousel__slide--visible > .carousel__item {
        opacity: 1;
        transform: rotateY(0);
    }
    .carousel__slide--next > .carousel__item {
        transform: scale(0.9) translate(-10px);
    }
    .carousel__slide--prev > .carousel__item {
        transform: scale(0.9) translate(10px);
    }
    .carousel__slide--active > .carousel__item {
        transform: scale(1.1);
    }

    /*Mail Contact*/

    .mail_contact{

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        /*opacity: 0.6;*/
        /*height: -webkit-fit-content;*/
        /*height: -moz-fit-content;*/
        height: fit-content;
        min-height: calc(200/16 * var(--default-size-unit));
        color: var(--white);
        /* background: var(--primary-text-color); */
        background-image: url('/public/images/ELMouradia-HomePage.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /*padding:  calc(45/16 * var(--default-size-unit)) var(--body-padding);*/
    }
    form {
        display: flex;
        flex-direction: column;
        height: calc(114/16 * var(--default-size-unit));
        max-width: 90%;
    }
    form label {
        font-size: var(--m-font-size);
        /*margin-bottom: 10px;*/
    }
    input {
        max-width: calc(420/16 * var(--default-size-unit));
        width: calc(420/16 * var(--default-size-unit));
        height: calc(50/16 * var(--default-size-unit));
        min-height: calc(50/16 * var(--default-size-unit));
        border: none;
        flex-grow: 1;
        background: var(--white);
        padding: calc(13/16 * var(--default-size-unit));
    }
    .input {
        margin-top: calc(12/16 * var(--default-size-unit));
        display: flex;
        justify-content: center;
        align-items: center;
        /*width: -webkit-fit-content;*/
        width: -moz-fit-content;
        width: fit-content;
        min-height: calc(50/16 * var(--default-size-unit));
        box-shadow: var(--card-shadow);
        border-radius: calc(4/16 * var(--default-size-unit));
        overflow: hidden;
    }
    .devider {
        width: calc(1/16 * var(--default-size-unit));
        height: calc(144/16 * var(--default-size-unit));
        background: var(--white);
        margin: 0 calc(110/16 * var(--default-size-unit));
    }
    @media (max-width: 991px)
    {
        .devider{
            height: calc(1/16 * var(--default-size-unit));
            width: 95%;
            background: var(--white);
            margin-right: 0!important;
            /*margin: calc(50/16 * var(--default-size-unit)) 0;*/
        }
        input{
            width: calc(420/22 * var(--default-size-unit));
        }
    }
    @media (max-width: 450px)
    {
        
        input{
            width: calc(350/22 * var(--default-size-unit));
        }

        .url-deco{
        text-decoration: none!important;
        color: #1a1e21;
        font-size: var(--s-font-size);
        font-family: NizarCoconKurdishBold!important;
    }
    .section-title {
        font-size: var(--s-font-size);
        font-weight: bold;
        line-height: calc(30/16 * var(--default-size-unit));
        padding: calc(5/16 * var(--default-size-unit)) calc(5/16 * var(--default-size-unit));
        background: #22845B!important;
        border-radius: calc(4/16 * var(--default-size-unit));
        color: var(--white);
        font-family: NizarCoconKurdishBold!important;
    }
    .mail_contact{
        margin-left: 0;
        margin-right: 0;
    }

    }

</style>
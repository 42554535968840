<template>
  <LoadingPage/>
  <Header />

  <!--<router-view class="view LoadingPage" name="LoadingPage"></router-view>-->
  <router-view class="view Carousel" name="Carousel"></router-view>
  <router-view class="view homeComponent" name="homeComponent"></router-view>
  <router-view class="view Gallery" name="Gallery"></router-view>
  <router-view class="view adminCouncil" name="adminCouncil"></router-view>
  <router-view class="view contact" name="contact"></router-view>
  <router-view class="view data" name="data"></router-view>
  <router-view class="view videos" name="videos"></router-view>
  <router-view class="view advertisements" name="advertisements"></router-view>


  <!--Hatplc-->
  <router-view class="view president" name="president"></router-view>
  <router-view class="view members" name="members"></router-view>
  <router-view class="view legislative" name="legislative"></router-view>
  <router-view class="view missions" name="missions"></router-view>
  <router-view class="view organizationalChart" name="organizationalChart"></router-view>
  <router-view class="view laws" name="laws"></router-view>
  <router-view class="view strategy" name="strategy"></router-view>
  <router-view class="view launchStrategy" name="launchStrategy"></router-view>
  <router-view class="view strategyImplementationPlan" name="strategyImplementationPlan"></router-view>
  <router-view class="view strategyIndicators" name="strategyIndicators"></router-view>
  <router-view class="view reports" name="reports"></router-view>
  <router-view class="view integrity" name="integrity"></router-view>
  <router-view class="view algerianTransparency" name="algerianTransparency"></router-view>
  <router-view class="view propertyDeclaration" name="propertyDeclaration"></router-view>
  <router-view class="view declarationForm" name="declarationForm"></router-view>
  <router-view class="view statistics" name="statistics"></router-view>
  <router-view class="view nationalArticles" name="nationalArticles"></router-view>
  <router-view class="view internationalArticles" name="internationalArticles"></router-view>
  <router-view class="view annualReport" name="annualReport"></router-view>
  <router-view class="view otherPublication" name="otherPublication"></router-view>
  <router-view class="view journal" name="journal"></router-view>
  <router-view class="view corruptionReport" name="corruptionReport"></router-view>


  <router-view class="view Detail_article" name="Detail_article"></router-view>
  <router-view class="view PageNotFound" name="PageNotFound"></router-view>

  <Footer />

</template>

<script>

import Header from './components/Header.vue'
import Footer from './components/Footer'
import LoadingPage from './components/LoadingPage'

export default {
  name: 'App',
  components: {
      Header,
      LoadingPage,
      Footer
  }
}
</script>


<template>

    <div class="container-carousel">
        <!--<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>-->
        <input type="radio" value="0" name="slider" v-model="slider" id="item-1" checked>
        <input type="radio" value="1" name="slider" v-model="slider" id="item-2">
        <input type="radio" value="2" name="slider" v-model="slider" id="item-3">
        <!--<input type="radio" value="3" name="slider" v-model="slider" id="item-4">-->
        <!--<input type="radio" value="4" name="slider" v-model="slider" id="item-5">-->

        <div class="cards">
            <label class="card" v-bind:id="'article-'+(cle+1)" v-for="(article, cle) in articles" :key="article.uuid" v-bind:for="'item-1'+(cle+1)">
                <router-link :to="{name: 'Detail_article', params: {id: article.uuid}}" @click="scrolltop">
                    <img :src="url_back +'images/head/'+ article.img_cover"  alt="{{ article.title }}" height="352px">
                    <div class="overlay"></div>
                    <div class="content" dir="rtl">
                        <div class="carousel-title" >{{ article.title }}</div>
                        <div class="line">
                            <div class="date" >{{ date_article(article.date_article) }}</div>
                        </div>
                    </div>
                </router-link>
            </label>
        </div>
        <div class="dots">
            <label for="item-1" id="dot-1"></label>
            <label for="item-2" id="dot-2"></label>
            <label for="item-3" id="dot-3"></label>
            <!--<label for="item-4" id="dot-4"></label>-->
            <!--<label for="item-5" id="dot-5"></label>-->
        </div>
    </div>

</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    export default {
        data(){
            return {
                slider: this.next(0),
                articles: Array,
                url_back: process.env.VUE_APP_ROOT_API + 'uploads/',
            }
        },
        name: "Carousel",
        created(){
            this.timer_slider();
            this.getArticles();

        },
        updated(){
            slider: this.next();
        },
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            },
            timer_slider() {
                let interval = setInterval(() => {
                    this.slider = ((this.slider + 1) % 3);
                    let count = this.slider;
                    // console.log(count);
                    this.next(count);
                }, 5000);
            },
            next(count){
                return (count) ;
            },

            async getArticles(){
                let url = process.env.VUE_APP_ROOT_API + 'api/carousel_articles';

                await axios.get(url).then(response =>{
                    this.articles = response.data.article_ars;
                }).catch(error => {
                    console.log(error);
                });
            },

            date_article(value){
                let day = moment(value).format('DD');
                let month = moment(value).format('MM');
                let year = moment(value).format('YYYY');

                if(month === "01"){
                    month = "جانفي";
                }else if(month === "02"){
                    month = "فيفري";
                }else if(month === "03"){
                    month = "مارس";
                }else if(month === "04"){
                    month = "أفريل";
                }else if(month === "05"){
                    month = "ماي";
                }else if(month === "06"){
                    month = "جوان";
                }else if(month === "07"){
                    month = "جويلية";
                }else if(month === "08"){
                    month = "أوت";
                }else if(month === "09"){
                    month = "سبتمبر";
                }else if(month === "10"){
                    month = "أكتوبر";
                }else if(month === "11"){
                    month = "نوفمبر";
                }else if(month === "12"){
                    month = "ديسمبر";
                }
                return day +' '+  month +' '+ year;
            },

        },
        mounted(){
            // console.log(this.articles)
        }

    }
</script>

<style scoped>

    img {
        width: 100%!important;
        height: calc(540/16 * 1rem)!important;
        /* Center the image within the element */
        -o-object-fit: cover!important;
        object-fit: cover!important;
        -o-object-position: center!important;
        object-position: center!important;
        background: rgba(91, 99, 109, 0.46)!important;
    }
    /*Start Carousel*/

    .card a{
        display: block;
        max-width: 100vw!important;
        width: calc(100vw - ((100vw - 1920px)/2 + calc(290/16 * 1rem))* 2)!important;
        /*height: calc(540/16 * 1rem)!important;*/
        height: calc(540/16 * 1rem)!important;
        padding: 0!important;
        color: white!important;
        font-weight: bold;
    }
    input[type=radio] {
        display: none;
    }
    .card {
        position: absolute!important;
        /*width: -webkit-fit-content;*/
        width: -moz-fit-content;
        width: fit-content;
        /*height: -webkit-fit-content;*/
        /*height: -moz-fit-content;*/
        height: 100%;
        left: 0!important;
        right: 0!important;
        margin: auto!important;
        transition: all 1.4s ease!important;
        /* cursor: pointer; */
    }
    .container-carousel {
        width: 100%!important;
        max-width: 100vw!important;
        max-height: calc(1000/16 * 1rem)!important;
        overflow: hidden!important;
        transform-style: preserve-3d!important;
        display: flex!important;
        justify-content: center!important;
        flex-direction: column!important;
        align-items: center!important;
    }
    .cards {
        position: relative!important;
        width: 100%!important;
        height: calc(540/18 * 1rem)!important;
    }

    #item-1:checked ~ .cards #article-3, #item-2:checked ~ .cards #article-1, #item-3:checked ~ .cards #article-2 {
        transform: translatex(-101%)!important;
        opacity: .3!important;
        z-index: 0!important;
    }
    #item-1:checked ~ .cards #article-2, #item-2:checked ~ .cards #article-3, #item-3:checked ~ .cards #article-1 {
        transform: translatex(101%)!important;
        opacity: .3!important;
        z-index: 0!important;
    }
    #item-1:checked ~ .cards #article-1, #item-2:checked ~ .cards #article-2, #item-3:checked ~ .cards #article-3 {
        transform: translatex(0) scale(1)!important;
        opacity: 1!important;
        z-index: 1!important;
    }


    .dots {
        position: absolute!important;
        bottom: calc(35/16 * 1rem)!important;
        /*min-width: -webkit-fit-content;*/
        /*min-width: -moz-fit-content;*/
        min-width: fit-content;
        display: flex!important;
        align-items: center!important;
        z-index: 10!important;
    }
    #item-1:checked ~ .dots #dot-1,#item-2:checked ~ .dots #dot-2,#item-3:checked ~ .dots #dot-3 {
        background: #FFF!important;
        z-index: 0!important;
    }
    .dots label {
        width: calc(50/16 * 1rem)!important;
        height: calc(5/16 * 1rem)!important;
        margin: 0 calc(4/16 * 1rem)!important;
        background: rgba(255, 255, 255, 0.44)!important;
        cursor: pointer!important;
    }
    .dots svg {
        cursor: pointer!important;
        margin: 0 1rem!important;
    }


    /*End Carousel*/
</style>
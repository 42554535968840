import { createRouter, createWebHistory } from "vue-router"

import Carousel               from '../components/Carousel'
import Gallery                from '../components/actions/gallery'
import contact                from '../components/contact'
import videos                 from '../components/events/videos'
import advertisements         from '../components/advertisements'

// hatplc
import president                  from '../components/authority/president'
import members                    from '../components/authority/membersCouncil'
import legislative                from '../components/authority/legislative'
import missions                   from '../components/authority/missions'
import organizationalChart        from '../components/authority/organizationalChart'
import laws                       from '../components/authority/laws'
import strategy                   from '../components/strategy/strategy'
import launchStrategy             from '../components/strategy/launchStrategy'
import strategyImplementationPlan from '../components/strategy/strategyImplementationPlan'
import strategyIndicators         from '../components/strategy/strategyIndicators'
import reports                    from '../components/strategy/reports'
import integrity                  from '../components/dossiers/integrity'
import algerianTransparency       from '../components/dossiers/algerianTransparency'
import propertyDeclaration        from '../components/declarations/propertyDeclaration'
import declarationForm            from '../components/declarations/declarationForm'
import statistics                 from '../components/declarations/statistics'
import nationalArticles           from '../components/articles/national'
import internationalArticles      from '../components/articles/international'
import annualReport               from '../components/publications/annualReport'
import otherPublication           from '../components/publications/otherPublication'
import journal                    from '../components/publications/journal'
import corruptionReport           from '../components/publications/corruptionReport'

// Activities
import Detail_article              from '../components/details/Detail_article'
import PageNotFound              from '../components/PageNotFound'
// CSJ

import homeComponent from '../components/home/homeComponent'

const routes = [
    {
        name: 'Gallery',
        path: '/pictures',
        components: {Gallery}
    },
    {
        name: 'contact',
        path: '/contact',
        components: {contact}
    },
    {
        name: 'videos',
        path: '/events-videos',
        components: {videos}
    },

    {
        name: 'Detail_article',
        path: '/detail-article/:id?',
        components: {Detail_article}
    },
    {
        name: 'advertisements',
        path: '/advertisements',
        components: {advertisements}
    },
    {
        name: 'president',
        path: '/president',
        components: {president}
    },
    {
        name: 'members',
        path: '/members',
        components: {members}
    },
    {
        name: 'legislative',
        path: '/legislative',
        components: {legislative}
    },
    {
        name: 'missions',
        path: '/missions',
        components: {missions}
    },
    {
        name: 'laws',
        path: '/laws',
        components: {laws}
    },
    {
        name: 'organizationalChart',
        path: '/organizational-chart',
        components: {organizationalChart}
    },
    {
        name: 'strategy',
        path: '/strategy',
        components: {strategy}
    },
    {
        name: 'launchStrategy',
        path: '/launch-strategy',
        components: {launchStrategy}
    },
    {
        name: 'strategyIndicators',
        path: '/strategy-indicators',
        components: {strategyIndicators}
    },
    {
        name: 'integrity',
        path: '/integrity',
        components: {integrity}
    },
    {
        name: 'algerianTransparency',
        path: '/algerian-transparency',
        components: {algerianTransparency}
    },
    {
        name: 'nationalArticles',
        path: '/national-articles',
        components: {nationalArticles}
    },
    {
        name: 'internationalArticles',
        path: '/international-articles',
        components: {internationalArticles}
    },
    {
        name: 'strategyImplementationPlan',
        path: '/strategy-implementation-plan',
        components: {strategyImplementationPlan}
    },
    {
        name: 'reports',
        path: '/reports',
        components: {reports}
    },
    {
        name: 'statistics',
        path: '/statistics',
        components: {statistics}
    },
    {
        name: 'declarationForm',
        path: '/declaration-form',
        components: {declarationForm}
    },
    {
        name: 'propertyDeclaration',
        path: '/property-declaration',
        components: {propertyDeclaration}
    },
    {
        name: 'annualReport',
        path: '/annual-report',
        components: {annualReport}
    },
    {
        name: 'otherPublication',
        path: '/other-publication',
        components: {otherPublication}
    },
    {
        name: 'journal',
        path: '/journal',
        components: {journal}
    },
    {
        name: 'corruptionReport',
        path: '/corruption-report',
        components: {corruptionReport}
    },
    {
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*',
        components: {PageNotFound}
    },


    {
        path: '/',
        components: {
            Carousel,homeComponent,
        },

    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('header.dossiers.title') }} > {{ $t('header.dossiers.algerianTransparency.title') }}</div>
        </div>
    </div>
    <div class="container mt-5 text-center mb-5" dir="rtl">
        <h1>
            الصفحة في طور الإنجاز !!!
        </h1>
    </div>
</template>

<script>
    export default {
        name: "algerianTransparency"
    }
</script>

<style scoped>
    ul.no-bullets {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .mot_president{
        color: rgba(50, 54, 57, 0.83);
    }
    .nav-link{
        color: rgba(80, 84, 87, 0.8) !important;
    }
    .active{
        color: rgba(28, 28, 28, 0.96)!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
    }
    .back-gray p{
        line-height: 200%;
    }
</style>